import React, {Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/reset.css";
import LoadingScreen from './loading';

const App = lazy(() => import ('./App'));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<LoadingScreen/>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);