import React from 'react'
import { 
    LoadingContainer,
    LoadingWrapper,
    LoadingText
} from './loadingElements'
import './styles/spinner.css'

const LoadingScreen = () => {
    return (
        <LoadingContainer>
            <LoadingWrapper>
                <LoadingText>Loading</LoadingText>
                    <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                    </div>
            </LoadingWrapper>
        </LoadingContainer>
    )
}

export default LoadingScreen
